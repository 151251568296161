import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

type CreateEmployeeParams = {
  payload: bnplMerchants.CreateEmployeeExcludeMerchantId;
};

type CreateEmployeeResponseData = {
  id: bnplMerchants.EmployeeMany["id"];
  created: bnplMerchants.EmployeeMany["created"];
  updated: bnplMerchants.EmployeeMany["updated"];
  archived: bnplMerchants.EmployeeMany["archived"];
  merchantId: bnplMerchants.EmployeeMany["merchant_id"];
  name: bnplMerchants.EmployeeMany["name"];
  role: bnplMerchants.EmployeeMany["role"];
  phone: bnplMerchants.EmployeeMany["phone"];
  email: bnplMerchants.EmployeeMany["email"];
};

export const createEmployee = async ({
  payload,
}: CreateEmployeeParams): Promise<CreateEmployeeResponseData> => {
  const response =
    await bnplMerchants.postApiMerchantsServiceEmployees(payload);
  if (!response.data.data) {
    const errorMessage = "Error creating employee";
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  const {
    id,
    created,
    updated,
    archived,
    merchant_id,
    name,
    role,
    phone,
    email,
  } = response.data.data;

  return {
    id,
    created,
    updated,
    archived,
    merchantId: merchant_id,
    name,
    role,
    phone,
    email,
  };
};
