import { useTranslation } from "react-i18next";
import { OrdersChartPoint } from "shared/api";
import { format } from "shared/lib";
import { Card, Stack, Typography } from "shared/ui";

type PointDetailsProps = {
  title: string;
  data: OrdersChartPoint | undefined;
};

const PointDetailsItem = ({
  label,
  value,
}: {
  label: string;
  value: string | number | undefined;
}) => (
  <>
    <Typography variant="smallTextRegular">{label}</Typography>
    <Typography variant="regularTextSemiBold" mt={0.5}>
      {value}
    </Typography>
  </>
);

export const PointDetails = ({ title, data }: PointDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ p: 2.5, backgroundColor: "neutral.96", height: 372 }}>
      <Stack justifyContent="space-between" sx={{ height: "100%" }}>
        <Typography variant="mediumTextSemiBold" mb={3.375}>
          {title}
        </Typography>

        <Stack spacing={1}>
          <PointDetailsItem
            label={t("overview.sales_chart.point_details.total_amount")}
            value={format(data?.totalAmount ?? 0)}
          />
          <PointDetailsItem
            label={t(
              "overview.sales_chart.point_details.total_settlement_amount",
            )}
            value={format(data?.totalSettlementAmount ?? 0)}
          />
          <PointDetailsItem
            label={t("overview.sales_chart.point_details.avg_amount")}
            value={format(data?.avgAmount ?? 0)}
          />
          <PointDetailsItem
            label={t("overview.sales_chart.point_details.total_orders_count")}
            value={data?.totalOrdersCount}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
