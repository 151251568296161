import { showSnackbarError } from "shared/lib";
import { axios } from "shared/lib/axios";

export const handleProfileMutationError = (error: Error) => {
  if (!(error instanceof axios.AxiosError)) return;

  const clientError = error?.response?.data?.error?.message;
  const validationErrors = error?.response?.data?.error?.errors;

  if (clientError) {
    showSnackbarError(clientError);
  }

  if (validationErrors) {
    Object.values(validationErrors).forEach((errorMessages) => {
      showSnackbarError((errorMessages as string[])?.[0]);
    });
  }
};
