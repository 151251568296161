import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

type UpdateEmployeeParams = {
  employeeId: string;
  payload: bnplMerchants.UpdateEmployee;
};

type UpdateEmployeeResponseData = {
  id: bnplMerchants.EmployeeMany["id"];
  created: bnplMerchants.EmployeeMany["created"];
  updated: bnplMerchants.EmployeeMany["updated"];
  archived: bnplMerchants.EmployeeMany["archived"];
  merchantId: bnplMerchants.EmployeeMany["merchant_id"];
  name: bnplMerchants.EmployeeMany["name"];
  role: bnplMerchants.EmployeeMany["role"];
  phone: bnplMerchants.EmployeeMany["phone"];
  email: bnplMerchants.EmployeeMany["email"];
};

export const updateEmployee = async ({
  employeeId,
  payload,
}: UpdateEmployeeParams): Promise<UpdateEmployeeResponseData> => {
  const response = await bnplMerchants.patchApiMerchantsServiceEmployeesId(
    employeeId,
    payload,
  );

  if (!response.data.data) {
    const errorMessage = "Error updating employee";
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  const {
    id,
    created,
    updated,
    archived,
    merchant_id,
    name,
    role,
    phone,
    email,
  } = response.data.data;

  return {
    id,
    created,
    updated,
    archived,
    merchantId: merchant_id,
    name,
    role,
    phone,
    email,
  };
};
