import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

export type GetOrdersStatusesResponseData =
  | bnplMerchants.GetOrdersStatuses["statuses"]
  | undefined;

export const getOrdersStatuses =
  async (): Promise<GetOrdersStatusesResponseData> => {
    const response = await bnplMerchants.getApiMerchantsServiceOrdersStatuses();

    if (!response.data.data?.statuses) {
      const errorMessage = "Error getting trade outlets names names";
      Sentry.captureException(errorMessage);
      throw new Error(errorMessage);
    }

    return response.data.data.statuses;
  };
