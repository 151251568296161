import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

export type GetTradeOutletNamesItem = {
  id?: bnplMerchants.GetTradeOutletsNamesMany["id"];
  name: bnplMerchants.GetTradeOutletsNamesMany["name"];
  location?: bnplMerchants.GetTradeOutletsNamesMany["location"];
};

export type GetTradeOutletsNamesResponseData =
  | GetTradeOutletNamesItem[]
  | undefined;

export const getTradeOutletsNames =
  async (): Promise<GetTradeOutletsNamesResponseData> => {
    const response =
      await bnplMerchants.getApiMerchantsServiceTradeOutletsNames();

    if (!response.data.data) {
      const errorMessage = "Error getting trade outlets names names";
      Sentry.captureException(errorMessage);
      throw new Error(errorMessage);
    }

    return response.data.data;
  };
