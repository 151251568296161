import { t } from "i18next";
import * as yup from "yup";

export const getMerchantSchema = () =>
  yup.object().shape({
    brandName: yup
      .string()
      .required(t("common.validation.required"))
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        t("common.validation.latin_letters"),
      ),
  });
