import { yupResolver } from "@hookform/resolvers/yup";
import { useBoolean } from "@mychili/ui-web";
import { useEmployeeOutlets } from "entities/employee-outlets";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GetEmployeesItem, GetTradeOutletNamesItem } from "shared/api";
import {
  BaseSwitch,
  Box,
  Button,
  CheckboxButton,
  IconCall,
  IconMail,
  IconPerson,
  Input,
  InputPhone,
  Loader,
  Stack,
  Typography,
} from "shared/ui";

import {
  getEmployeeSchema,
  getTradeOutletsAccessState,
  TradeOutletsAccessState,
} from "../lib";

type EmployeeFormData = {
  name: GetEmployeesItem["name"];
  phone: GetEmployeesItem["phone"];
  email: GetEmployeesItem["email"];
};

export type EmployeeFormDataForSubmit = EmployeeFormData & {
  tradeOutletsAccessIds: TradeOutletsAccessState;
  isRefundAllowed: boolean;
};

type EmployeeFormProps = {
  onClose: () => void;
  onSubmit: (formData: EmployeeFormDataForSubmit) => void;
  initialData?: GetEmployeesItem;
  tradeOutletsOptions: GetTradeOutletNamesItem[];
};

export const EmployeeForm = ({
  onClose,
  onSubmit,
  initialData,
  tradeOutletsOptions,
}: EmployeeFormProps) => {
  const { t } = useTranslation();

  const isRefundAllowed = useBoolean(
    initialData?.roles?.includes("refund_accessed_employee"),
  );

  const employeeOutlets = useEmployeeOutlets({
    enabled: Boolean(initialData),
    variables: {
      employee_id: initialData?.id,
    },
  });

  const [tradeOutletsAccessState, setTradeOutletsAccessState] =
    useState<TradeOutletsAccessState>(
      getTradeOutletsAccessState(
        tradeOutletsOptions,
        employeeOutlets.data?.items || [],
      ),
    );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmployeeFormData>({
    defaultValues: initialData,
    resolver: yupResolver(getEmployeeSchema()),
    reValidateMode: "onSubmit",
  });

  const handleFormSubmit = (formData: EmployeeFormData) => {
    onSubmit({
      ...formData,
      tradeOutletsAccessIds: tradeOutletsAccessState,
      isRefundAllowed: isRefundAllowed.value,
    });
  };

  const handleCheckboxChange = (id: string, value: boolean) => {
    setTradeOutletsAccessState((currentState) => ({
      ...currentState,
      [id]: value,
    }));
  };

  if (employeeOutlets.isLoading) return <Loader />;

  return (
    <Box py={2.5}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box
          p={2.5}
          sx={{
            height: 392,
            overflowY: "auto",
            borderTop: "1px solid",
            borderColor: "neutral.95",
          }}
        >
          <Typography mb={2} variant="smallTextMedium">
            {t("employees.form.information.label")}
          </Typography>

          <Stack spacing={1}>
            <Input
              {...register("name")}
              placeholder={t("employees.form.name.placeholder")}
              InputProps={{
                startAdornment: <IconPerson />,
                sx: {
                  // @TODO Remove after adding small inputs to DS
                  ".MuiInputBase-input": {
                    cursor: "pointer",
                    py: 1.5,
                    fontSize: 14,
                    lineHeight: 21,
                  },
                },
              }}
              color="gray"
              error={Boolean(errors.name?.message)}
              helperText={errors.name?.message}
            />
            <InputPhone
              countryCode="MY"
              {...register("phone")}
              placeholder={t("employees.form.phone.placeholder")}
              InputProps={{
                startAdornment: <IconCall />,
                sx: {
                  // @TODO Remove after adding small inputs to DS
                  ".MuiInputBase-input": {
                    cursor: "pointer",
                    py: 1.5,
                    fontSize: 14,
                    lineHeight: 21,
                  },
                },
              }}
              color="gray"
              error={Boolean(errors.phone?.message)}
              helperText={errors.phone?.message}
            />
            <Input
              {...register("email")}
              placeholder={t("employees.form.email.placeholder")}
              InputProps={{
                startAdornment: <IconMail />,
                sx: {
                  // @TODO Remove after adding small inputs to DS
                  ".MuiInputBase-input": {
                    cursor: "pointer",
                    py: 1.5,
                    fontSize: 14,
                    lineHeight: 21,
                  },
                },
              }}
              color="gray"
              error={Boolean(errors.email?.message)}
              helperText={errors.email?.message}
            />
          </Stack>

          <Typography mt={3} variant="smallTextMedium">
            {t("employees.form.stores_access.label")}
          </Typography>

          <Box mt={2}>
            <Stack direction="row" spacing={1.5}>
              <BaseSwitch
                checked={isRefundAllowed.value}
                onChange={isRefundAllowed.toggle}
              />
              <Typography variant="regularTextRegular" color="neutral.20">
                {t("employees.form.refund_access.label")}
              </Typography>
            </Stack>
          </Box>

          <Stack spacing={1} mt={1.5}>
            {tradeOutletsOptions.map(({ id, name, location }) => (
              <CheckboxButton
                key={id}
                checked={tradeOutletsAccessState[id!]}
                onChange={(e) => handleCheckboxChange(id!, e.target.checked)}
                text={name}
                description={location ?? ""}
                color="gray"
              />
            ))}
          </Stack>
        </Box>

        <Stack
          direction="row"
          spacing={1}
          pt={2.5}
          px={2.5}
          boxShadow="-1px 0px 8px 0px rgba(65, 69, 77, 0.02), 4px 0px 8px 0px rgba(65, 69, 77, 0.04);"
          sx={{
            borderTop: "1px solid",
            borderColor: "neutral.95",
          }}
        >
          <Button onClick={onClose} fullWidth variant="secondary">
            {t("common.back")}
          </Button>
          <Button type="submit" fullWidth>
            {t(
              initialData
                ? "common.save_changes"
                : "employees.form.submit_button_text",
            )}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
