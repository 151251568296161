import { Box, Stack, Typography } from "shared/ui";

type LineChartLegendItem = {
  title: string;
  color: string;
};

type LineChartLegendProps = {
  items: LineChartLegendItem[];
};

export const LineChartLegend = ({ items }: LineChartLegendProps) => (
  <Stack
    direction="row"
    spacing={2}
    py={1.5}
    px={2}
    sx={{ borderRadius: 1, backgroundColor: "neutral.96" }}
  >
    {items.map(({ title, color }) => (
      <Stack direction="row" alignItems="center" spacing={1} key={title}>
        <Box
          width={10}
          height={10}
          sx={{ borderRadius: 0.5, backgroundColor: color }}
        />
        <Typography variant="regularTextRegular" color="neutral.50">
          {title}
        </Typography>
      </Stack>
    ))}
  </Stack>
);
