import { IconClose } from "@mychili/ui-web";

import { Box, Dialog, IconButton, Stack, Typography } from "..";

export type BaseDialogProps = {
  children?: React.ReactNode;
  title: string;
  isOpen: boolean;
  onClose: () => void;
};

export const BaseDialog = ({
  children,
  onClose,
  isOpen,
  title,
}: BaseDialogProps) => {
  return (
    <Dialog fullWidth={true} onClose={onClose} open={isOpen} maxWidth="md">
      <Stack justifyContent="space-between" position="relative">
        <Typography
          px={2.5}
          pt={2.5}
          variant="bigTextSemiBold"
          textAlign="left"
        >
          {title}
        </Typography>

        <IconButton
          onClick={onClose}
          sx={{
            height: 24,
            width: 24,
            color: "neutral.20",
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <IconClose />
        </IconButton>

        <Box>{children}</Box>
      </Stack>
    </Dialog>
  );
};
