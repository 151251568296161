import { useTranslation } from "react-i18next";
import { GetTradeOutletsItem } from "shared/api";
import { showSnackbarSuccess } from "shared/lib";
import { handleAxiosError } from "shared/lib/axios";
import { BaseDialog, Box, Button, Stack, Typography } from "shared/ui";

import { useDeleteTradeOutlet } from "../model";

type DeleteTradeOutletDialogProps = {
  tradeOutlet?: GetTradeOutletsItem;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const DeleteTradeOutletDialog = ({
  tradeOutlet,
  isOpen,
  onClose,
  onSuccess,
}: DeleteTradeOutletDialogProps) => {
  const { t } = useTranslation();

  const deleteTradeOutlet = useDeleteTradeOutlet();

  const handleDelete = () => {
    if (tradeOutlet?.id) {
      deleteTradeOutlet.mutate(
        {
          tradeOutletId: tradeOutlet?.id,
        },
        {
          onError: (e) => {
            handleAxiosError(e);
          },
          onSuccess: () => {
            onClose();
            onSuccess();

            showSnackbarSuccess(
              t("trade_outlets.notification.delete_trade_outlet_success"),
            );
          },
        },
      );
    }
  };

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("trade_outlets.delete_trade_outlet_modal.title")}
    >
      <Box p={2.5}>
        <Typography color="neutral.50" variant="regularTextRegular">
          {t("trade_outlets.delete_trade_outlet_modal.confirmation.text", {
            name: tradeOutlet?.name,
          })}
        </Typography>
        <Typography mt={0.5} color="neutral.50" variant="regularTextRegular">
          {t("trade_outlets.delete_trade_outlet_modal.confirmation.warning")}
        </Typography>

        <Stack direction="row" spacing={1} width="100%" mt={2}>
          <Button onClick={onClose} variant="secondary" fullWidth>
            {t("common.close")}
          </Button>
          <Button onClick={handleDelete} variant="primary" fullWidth>
            {t("trade_outlets.delete_trade_outlet_modal.delete_button_text")}
          </Button>
        </Stack>
      </Box>
    </BaseDialog>
  );
};
