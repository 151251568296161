import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

export type OrdersCountByStatusesItem =
  bnplMerchants.CountOrdersByStatusesRespMany;

export type GetOrdersCountByStatusesResponseData = OrdersCountByStatusesItem[];

export const getOrdersCountByStatuses = async (
  options: bnplMerchants.GetApiMerchantsServiceOrdersCountByStatusesParams,
): Promise<GetOrdersCountByStatusesResponseData> => {
  const response =
    await bnplMerchants.getApiMerchantsServiceOrdersCountByStatuses(options);

  if (!response.data.data) {
    const errorMessage = "Error getting orders data";
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  return response.data.data;
};
