import { yupResolver } from "@hookform/resolvers/yup";
import { useEmployee } from "entities/employees";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { parseLocalPhoneNumber, showSnackbarSuccess } from "shared/lib";
import {
  Button,
  CloseButton,
  IconCall,
  IconMail,
  IconPerson,
  Input,
  InputPhone,
  Loader,
  Stack,
} from "shared/ui";

import { getProfileSchema, handleProfileMutationError } from "../../lib";
import { useUpdateProfile } from "../../model";

type ProfileFormData = {
  name: string;
  phone: string;
  email: string;
};

type ProfileFormProps = {
  onBackClick: () => void;
};

export const ProfileForm = ({ onBackClick }: ProfileFormProps) => {
  const { t } = useTranslation();

  const { data, isLoading } = useEmployee();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm<ProfileFormData>({
    defaultValues: data,
    resolver: yupResolver(getProfileSchema()),
    reValidateMode: "onSubmit",
  });

  const updateProfile = useUpdateProfile();

  const handleFormSubmit = ({ name, phone, email }: ProfileFormData) => {
    updateProfile.mutate(
      {
        employeeId: data?.id!,
        payload: {
          name,
          phone: parseLocalPhoneNumber(phone),
          email,
        },
      },
      {
        onSuccess: () => {
          showSnackbarSuccess(t("common.notification.changes_saved"));
        },
        onError: handleProfileMutationError,
      },
    );
  };

  const handleInputClear = (fieldName: keyof ProfileFormData) => () => {
    reset({ [fieldName]: "" });
  };

  if (isLoading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing={2} p={2.5}>
        <Input
          InputProps={{
            startAdornment: <IconPerson />,
            endAdornment: <CloseButton onClick={handleInputClear("name")} />,
            sx: {
              // @TODO Remove after adding small inputs to DS
              ".MuiInputBase-input": {
                cursor: "pointer",
                py: 1.5,
                fontSize: 14,
                lineHeight: 21,
              },
            },
          }}
          color="gray"
          {...register("name")}
          error={Boolean(errors.name?.message)}
          helperText={errors.name?.message}
        />
        <InputPhone
          countryCode="MY"
          {...register("phone")}
          InputProps={{
            startAdornment: <IconCall />,
            endAdornment: <CloseButton onClick={handleInputClear("phone")} />,
            sx: {
              // @TODO Remove after adding small inputs to DS
              ".MuiInputBase-input": {
                cursor: "pointer",
                py: 1.5,
                fontSize: 14,
                lineHeight: 21,
              },
            },
          }}
          color="gray"
          error={Boolean(errors.phone?.message)}
          helperText={errors.phone?.message}
        />
        <Input
          InputProps={{
            startAdornment: <IconMail />,
            endAdornment: <CloseButton onClick={handleInputClear("email")} />,
            sx: {
              // @TODO Remove after adding small inputs to DS
              ".MuiInputBase-input": {
                cursor: "pointer",
                py: 1.5,
                fontSize: 14,
                lineHeight: 21,
              },
            },
          }}
          color="gray"
          {...register("email")}
          error={Boolean(errors.email?.message)}
          helperText={errors.email?.message}
        />

        <Stack direction="row" spacing={1}>
          <Button variant="secondary" fullWidth onClick={onBackClick}>
            {t("common.back")}
          </Button>
          <Button disabled={!isDirty} type="submit" fullWidth>
            {t("common.save")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
