import { showSnackbarError } from "shared/lib";
import { axios } from "shared/lib/axios";

export const handleAxiosError = (error: Error) => {
  if (!(error instanceof axios.AxiosError)) return;

  const clientError = error?.response?.data?.error?.message;

  if (clientError) {
    showSnackbarError(clientError);
  }
};
