import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

export const uploadMerchantLogo = async (
  logo: bnplMerchants.PostApiMerchantsServiceCrmMerchantsIdUploadLogoBody["logo"],
) => {
  const response =
    await bnplMerchants.postApiMerchantsServiceMerchantUploadLogo({ logo });

  if (!response.data.data) {
    const errorMessage = "Error uploading merchant logo";
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  return response.data.data;
};
