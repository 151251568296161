import * as BaseSentry from "@sentry/react";

const init = () => {
  BaseSentry.init({
    dsn: "https://34d3b75b844ddf5e19010ba72832c7ca@sentry.fingular.com/50",
    enabled: import.meta.env.PROD,
  });
};

export const Sentry = {
  addBreadcrumb: BaseSentry.addBreadcrumb,
  captureException: BaseSentry.captureException,
  init,
  setUser: BaseSentry.setUser,
};
