import { useOrdersChartData, useOrdersCountByStatuses } from "entities/orders";
import { useTradeOutletsOptions } from "entities/trade-outlets";
import { OrdersCountChart } from "features/orders-count-chart";
import { OrdersSalesChart } from "features/orders-sales-chart";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrdersChartPoint } from "shared/api";
import { formatDate } from "shared/lib";
import {
  BaseTabs,
  Box,
  Card,
  DatePicker,
  DatePickerValue,
  LoadingOverlay,
  RadioSelect,
  Show,
  Stack,
  Typography,
} from "shared/ui";

import {
  getDefaultDates,
  getPeriodDisplayValue,
  getPointDetailsTitle,
  getSalesChartData,
} from "../lib";
import { LineChartViewType } from "../types";
import { AverageOrderAmount } from "./average-order-amount";
import { LineChartLegend } from "./line-chart-legend";
import { PointDetails } from "./point-detailts";

export const OrdersOverview = () => {
  const { t } = useTranslation();

  const [dates, setDates] = useState<[Date, Date]>(getDefaultDates());
  const [tradeOutletId, setTradeOutletId] = useState<string>("all");

  const chartData = useOrdersChartData({
    created_ge: formatDate(dates?.[0]),
    created_le: formatDate(dates?.[1]),
    trade_outlet_id: tradeOutletId === "all" ? undefined : tradeOutletId,
    status_in: [
      "ACTIVE",
      "CONFIRMED",
      "PAYMENT_FAILED",
      "PENDING",
      "PROCESSING",
      "REFUNDED",
      "REFUND_FAILED",
      "REFUND_IN_PROGRESS",
      "REPAID",
    ],
  });

  const countChartData = useOrdersCountByStatuses({
    created_ge: formatDate(dates?.[0]),
    created_le: formatDate(dates?.[1]),
    trade_outlet_id: tradeOutletId === "all" ? undefined : tradeOutletId,
  });

  const tradeOutletsOptions = useTradeOutletsOptions();

  const [chartView, setChartView] = useState<LineChartViewType>("days");
  const [selectedTick, setSelectedTick] = useState<string>();

  const handleViewChange = (_: React.SyntheticEvent, value: string) => {
    setChartView(value as LineChartViewType);

    if (chartData.data?.[value as LineChartViewType]?.[0]) {
      const firstTickData = chartData.data?.[value as LineChartViewType]?.[0];
      setSelectedTick(firstTickData?.intervalStartDate);
    }
  };

  const handleTickClick = (tick?: string) => {
    setSelectedTick(tick);
  };

  const handleDatesChange = (value: DatePickerValue) => {
    setDates(value as [Date, Date]);
  };

  const handleSelectChange = (value: string) => {
    setTradeOutletId(value);
  };

  const handleDatesReset = () => {
    setDates(getDefaultDates());
  };

  const selectedTickData = useMemo<OrdersChartPoint | undefined>(() => {
    const data = chartData.data?.[chartView];

    return data?.find((item) => item.intervalStartDate === selectedTick);
  }, [selectedTick, chartView, chartData]);

  useEffect(() => {
    if (chartData.data) {
      const firstTickData = chartData.data?.[chartView]?.[0];
      setSelectedTick(firstTickData?.intervalStartDate);
    }
  }, [chartData.data, chartView]);

  const { salesChartData, isSingleSalesChartDataPoint } = getSalesChartData(
    chartData.data?.[chartView],
  );

  const selectedTradeOutletName = useMemo<string | undefined>(() => {
    const selectedTradeOutlet = tradeOutletsOptions.data?.find(
      (item) => item.id === tradeOutletId,
    );

    return selectedTradeOutlet?.id ? selectedTradeOutlet?.name : "all stores";
  }, [tradeOutletId, tradeOutletsOptions.data]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2.5}
      >
        <Typography variant="headlineH1">{t("overview.title")}</Typography>

        <Stack width={568} direction="row" spacing={1}>
          <RadioSelect
            options={
              tradeOutletsOptions.data
                ? [
                    {
                      id: "all",
                      name: t("overview.stores_filter.all_stores_label"),
                    },
                    ...tradeOutletsOptions.data,
                  ]
                : []
            }
            onChange={handleSelectChange}
            value={tradeOutletId}
            color="white"
          />
          <DatePicker
            value={dates}
            onChange={handleDatesChange}
            onReset={handleDatesReset}
            maxDate={new Date()}
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          display: "grid",
          gap: 1,
          gridTemplateColumns: "auto 937px",
          height: "529px",
        }}
      >
        <Box>
          <AverageOrderAmount
            amount={chartData.data?.avgAmount}
            amountDiff={chartData.data?.avgAmountDiff}
          />

          <Card sx={{ p: 2.5, mt: 1, height: 379 }}>
            <Typography variant="mediumTextSemiBold">
              {t("overview.orders_count_chart.title")}
            </Typography>

            <Show when={!countChartData.isLoading}>
              <OrdersCountChart data={countChartData.data || []} />
            </Show>
          </Card>
        </Box>

        <Card sx={{ p: 2.5, width: 937 }}>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography variant="mediumTextSemiBold">
                {t("overview.sales_chart.title", {
                  name: selectedTradeOutletName,
                })}
              </Typography>
              <Typography variant="smallTextRegular" color="neutral.50">
                {getPeriodDisplayValue(dates)}
              </Typography>
            </Box>

            <Box>
              <BaseTabs
                items={[
                  {
                    label: t("overview.sales_chart.view_control_tab.days"),
                    value: "days",
                  },
                  {
                    label: t("overview.sales_chart.view_control_tab.weeks"),
                    value: "weeks",
                  },
                  {
                    label: t("overview.sales_chart.view_control_tab.months"),
                    value: "months",
                  },
                ]}
                value={chartView}
                onChange={handleViewChange}
              />
            </Box>

            <LineChartLegend
              items={[
                {
                  title: t("overview.sales_chart.legend.gross"),
                  color: "common.primary",
                },
                {
                  title: t("overview.sales_chart.legend.net"),
                  color: "success.70",
                },
              ]}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between" mt={2.5}>
            <OrdersSalesChart
              data={salesChartData}
              isSingleDataPoint={isSingleSalesChartDataPoint}
              selectedTick={selectedTick}
              selectedView={chartView}
              onTickClick={handleTickClick}
            />

            <Show when={Boolean(selectedTickData)}>
              <PointDetails
                title={getPointDetailsTitle(selectedTickData, chartView)}
                data={selectedTickData}
              />
            </Show>
          </Stack>
        </Card>
      </Box>

      <LoadingOverlay open={chartData.isLoading || countChartData.isLoading} />
    </>
  );
};
