import { getSession } from "entities/sessions";
import { redirect } from "react-router-dom";
import { getEmployee } from "shared/api";
import { ROUTES } from "shared/routing";

export const protectedPageLoader = async () => {
  const { accessToken } = getSession();

  if (!accessToken) {
    return redirect(ROUTES.welcome);
  }

  const employee = await getEmployee();

  const isAdmin = employee?.roles?.includes("admin");

  if (!isAdmin) {
    return redirect(ROUTES.accessDenied);
  }

  return null;
};
