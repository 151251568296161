import { t } from "i18next";
import * as yup from "yup";

export const getProfileSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required(t("common.validation.required"))
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        t("common.validation.latin_letters"),
      ),
    phone: yup
      .string()
      .required(t("common.validation.required"))
      .transform((value) => value.replace(/\s/g, ""))
      .test(
        "phoneDigitsCount",
        t("common.validation.phone_length"),
        (value: string) => {
          // @todo move phone validation rules to config
          const isLongNumber = value.startsWith("011");
          return isLongNumber
            ? value.length === 11
            : value.length === 10 || value.length === 11;
        },
      ),
    email: yup
      .string()
      .required(t("common.validation.required"))
      .email(t("common.validation.email")),
  });
