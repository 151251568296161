import { useMutation } from "@tanstack/react-query";
import { queryClient } from "app/providers";
import { getSession, unsetSession } from "entities/sessions";
import { logout } from "shared/api";
import { Sentry } from "shared/lib/sentry";

export const useLogout = () => {
  const { accessToken } = getSession();

  return useMutation({
    mutationFn: () => {
      if (!accessToken) {
        Sentry.captureException("Try to logout without access token");
        throw new Error("Try to logout without access token");
      }

      return logout({ accessToken });
    },
    onSettled: () => {
      unsetSession();
      queryClient.clear();
    },
  });
};
