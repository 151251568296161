import { IconArrowDown, IconArrowUp } from "@mychili/ui-web";
import { useTranslation } from "react-i18next";
import { format } from "shared/lib";
import { Box, Card, Stack, Typography } from "shared/ui";

type AverageOrderAmountProps = {
  amount: number | undefined;
  amountDiff: number | undefined;
};

export const AverageOrderAmount = ({
  amount = 0,
  amountDiff = 0,
}: AverageOrderAmountProps) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ p: 2.5, height: 142 }}>
      <Typography variant="mediumTextSemiBold">
        {t("overview.average_order_amount.title")}
      </Typography>

      <Typography variant="headlineH2" mt={2}>
        {format(amount)}
      </Typography>

      <Stack direction="row" spacing={0.5} mt={1}>
        {amountDiff >= 0 ? (
          <IconArrowUp color="success" fontSize="small" />
        ) : (
          <IconArrowDown color="error" />
        )}
        <Box>
          <Typography
            color={amountDiff >= 0 ? "success.70" : "error.70"}
            display="inline"
            variant="regularTextSemiBold"
          >
            {format(amountDiff)}
          </Typography>{" "}
          <Typography
            color="neutral.50"
            variant="regularTextRegular"
            display="inline"
          >
            {t("overview.average_order_amount.previous_period.label")}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};
