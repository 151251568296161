import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

type CreateTradeOutletParams = {
  payload: bnplMerchants.TradeOutletOnlyAgreementIdIsOnlineLocationLocationGeoName;
};

type CreateTradeOutletResponseData = {
  id: bnplMerchants.TradeOutletMany["id"];
  created: bnplMerchants.TradeOutletMany["created"];
  updated: bnplMerchants.TradeOutletMany["updated"];
  archived: bnplMerchants.TradeOutletMany["archived"];
  name: bnplMerchants.TradeOutletMany["name"];
  isOnline: bnplMerchants.TradeOutletMany["is_online"];
  locationGeo: bnplMerchants.TradeOutletMany["location_geo"];
  location: bnplMerchants.TradeOutletMany["location"];
  merchantId: bnplMerchants.TradeOutletMany["merchant_id"];
  agreementId: bnplMerchants.TradeOutletMany["agreement_id"];
};

export const createTradeOutlet = async ({
  payload,
}: CreateTradeOutletParams): Promise<CreateTradeOutletResponseData> => {
  const response =
    await bnplMerchants.postApiMerchantsServiceTradeOutlets(payload);

  if (!response.data.data) {
    const errorMessage = "Error creating store";
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  const {
    id,
    created,
    updated,
    archived,
    name,
    is_online,
    location_geo,
    location,
    merchant_id,
    agreement_id,
  } = response.data.data;

  return {
    id,
    created,
    updated,
    archived,
    name,
    isOnline: is_online,
    locationGeo: location_geo,
    location,
    merchantId: merchant_id,
    agreementId: agreement_id,
  };
};
