import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "shared/routing";
import { Box, Container, Stack, TopNavigationBar } from "shared/ui";
import { Navigation } from "widgets/navigation";

type LayoutMainProps = {
  children?: ReactNode;
};

export const LayoutMain = ({ children }: LayoutMainProps) => {
  return (
    <Box minWidth={1280}>
      <TopNavigationBar maxWidth={1304}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={10}
          sx={{ width: "100%", minWidth: 800 }}
        >
          <Link to={ROUTES.orders}>
            <Box
              component="img"
              display="block"
              width={92}
              src="https://static.ahapay.my/logos/full.svg"
            />
          </Link>

          <Navigation />
        </Stack>
      </TopNavigationBar>

      <Box mt={2.5}>
        <Container>{children}</Container>
      </Box>
    </Box>
  );
};
