import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

export const uploadTradeOutletLogo = async (
  id: string,
  body: bnplMerchants.PostApiMerchantsServiceCrmMerchantsIdUploadLogoBody,
) => {
  const response =
    await bnplMerchants.postApiMerchantsServiceTradeOutletsIdUploadLogo(
      id,
      body,
    );

  if (!response.data.data) {
    const errorMessage = "Error uploading store logo";
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  return response.data.data;
};
