import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { analytics } from "shared/lib";
import { handleAxiosError } from "shared/lib/axios";
import { ROUTES } from "shared/routing";
import { Box, Button, Typography } from "shared/ui";

import { useConfirmCode } from "../../model";
import { EnterCodeInput } from "../enter-code-input";
import { ResendCodeButton } from "../resend-code-button";

export type ConfirmCodeViewProps = {
  authId: string;
  countdown?: number;
  isInvalid?: boolean;
  onBack: () => void;
  onResend: () => void;
  phoneNumber: string;
};

export const ConfirmCodeView = ({
  authId,
  countdown = 0,
  onBack,
  onResend,
  phoneNumber,
}: ConfirmCodeViewProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const confirmCode = useConfirmCode();

  const handleCodeConfirm = (code: string) => {
    confirmCode.mutate(
      {
        appVersion: "web", // TODO: to config
        authId,
        code,
      },
      {
        onSuccess: () => {
          analytics.logAction("Login");
          navigate(ROUTES.overview);
        },
        onError: (e) => {
          handleAxiosError(e);
        },
      },
    );
  };

  return (
    <Box>
      <Typography mb={2} textAlign="center" variant="regularTextRegular">
        {t("login.confirm.title")} <b>{phoneNumber}</b>
      </Typography>
      <Box mb={2}>
        <EnterCodeInput
          error={confirmCode.isError}
          helperText={confirmCode.isError ? t("login.confirm.error") : null}
          onConfirm={handleCodeConfirm}
        />
      </Box>
      <Box mb={2} textAlign="center">
        <ResendCodeButton countdown={countdown} onResend={onResend} />
      </Box>
      <Button
        onClick={onBack}
        sx={{ position: "fixed", bottom: 16, left: 16 }}
        variant="outlined"
      >
        {t("common.back")}
      </Button>
    </Box>
  );
};
