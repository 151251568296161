import { queryClient } from "app/providers";
import { jwtDecode } from "jwt-decode";
import { setAnalyticsSession } from "shared/lib";
import { Sentry } from "shared/lib/sentry";

import {
  ACCESS_TOKEN_STORAGE_KEY,
  queryKey,
  REFRESH_TOKEN_STORAGE_KEY,
} from "../../lib";

export type Session = {
  accessToken: string | null;
  refreshToken: string | null;
};

export const setSession = (
  tokens: Pick<Session, "accessToken" | "refreshToken">,
) => {
  const { accessToken, refreshToken } = tokens;

  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  }

  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
  }

  try {
    const { employee_id } = jwtDecode<{ employee_id: string }>(accessToken!);

    Sentry.setUser({ id: employee_id });

    setAnalyticsSession(accessToken);
    // https://tkdodo.eu/blog/practical-react-query#dont-use-the-querycache-as-a-local-state-manager
    return queryClient.setQueryData(queryKey, { accessToken, refreshToken });
  } catch {
    Sentry.captureException("Access token error");
  }
};
