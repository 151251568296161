import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

import { GetMerchantResponseData } from "./get-merchant";

export const updateMerchant = async (
  options: bnplMerchants.UpdateMerchantSchema,
): Promise<GetMerchantResponseData> => {
  const response =
    await bnplMerchants.patchApiMerchantsServiceMerchant(options);

  if (!response.data.data) {
    const errorMessage = "Error updating merchant";
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  const {
    address,
    archived,
    created,
    id,
    logo_url,
    name,
    owner,
    registration_number,
    updated,
    website,
    brand_name,
  } = response.data.data;

  return {
    address,
    archived,
    created: created ? new Date(created) : undefined,
    id,
    logoUrl: logo_url,
    name,
    owner,
    registrationNumber: registration_number,
    updated: updated ? new Date(updated) : undefined,
    website,
    brandName: brand_name,
  };
};
