import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { Sentry } from "shared/lib/sentry";

export type GetEmployeesNamesResponseData =
  | {
      id?: bnplMerchants.GetEmployeesNamesMany["id"];
      name: bnplMerchants.GetEmployeesNamesMany["name"];
    }[]
  | undefined;

export const getEmployeesNames =
  async (): Promise<GetEmployeesNamesResponseData> => {
    const response = await bnplMerchants.getApiMerchantsServiceEmployeesNames();

    if (!response.data.data) {
      const errorMessage = "Error getting employees names";
      Sentry.captureException(errorMessage);
      throw new Error(errorMessage);
    }

    return response.data.data;
  };
